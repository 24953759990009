<template>
  <div class="article">
    <div class="head">
      <h5>현황판 색 설정</h5>
    </div>
    <div class="form">
      <form>
        <h5>실시간 온습도 현황</h5>
        <ul>
          <li>
            <div>
              <input
                id="thBgColorSanche"
                type="color"
                v-model="background_pick"
              />
            </div>
            <label for="thBgColorSanche">전체 배경</label>
          </li>
          <li>
            <div>
              <input id="thBdColorSanche" type="color" v-model="border_pick" />
            </div>
            <label for="thBdColorSanche">테이블 외부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="thBdiColorSanche"
                type="color"
                v-model="border_inner"
              />
            </div>
            <label for="thBdiColorSanche">테이블 내부 테두리</label>
          </li>
          <li>
            <div>
              <input
                id="thCrtColorSanche"
                type="color"
                v-model="background_temp"
              />
            </div>
            <label for="thCrtColorSanche">테이블 제목 배경(첫번째)</label>
          </li>
          <li>
            <div>
              <input id="thCrtFontSanche" type="color" v-model="color_temp" />
            </div>
            <label for="thCrtFontSanche"> 테이블 제목 글자(첫번째) </label>
          </li>
          <li>
            <div>
              <input
                id="thDivColorSanche"
                type="color"
                v-model="background_division"
              />
            </div>
            <label for="thDivColorSanche">테이블 제목 배경(두번째)</label>
          </li>
          <li>
            <div>
              <input
                id="thDivFontSanche"
                type="color"
                v-model="color_division"
              />
            </div>
            <label for="thDivFontSanche">테이블 제목 글자 (두번째)</label>
          </li>
        </ul>
        <ul>
          <li>
            <div>
              <input
                id="thTableColorSanche"
                type="color"
                v-model="background_table"
              />
            </div>
            <label for="thTableColorSanche">테이블 내용 배경 </label>
          </li>
          <li>
            <div>
              <input
                id="thDetailColorSanche"
                type="color"
                v-model="color_detail"
              />
            </div>
            <label for="thDetailColorSanche">테이블 내용 글자(구분)</label>
          </li>
          <li>
            <div>
              <input
                id="thDetailFontSanche"
                type="color"
                v-model="color_detail_temp"
              />
            </div>
            <label for="thDetailFontSanche">테이블 내용 글자(온도, 습도)</label>
          </li>
          <li>
            <div>
              <input
                id="thBtnColorSanche"
                type="color"
                v-model="background_button"
              />
            </div>
            <label for="thBtnColorSanche">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="thBtnFontSanche" type="color" v-model="color_button" />
            </div>
            <label for="thBtnFontSanche">전체화면 버튼 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="tempColorSanche"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormTH">저장</button>
        </div>
      </form>
      <form>
        <h5>실시간 금속검출기 현황</h5>
        <ul>
          <li>
            <div>
              <input
                id="metalBgColorSanche"
                type="color"
                v-model="background_bad"
              />
            </div>
            <label for="metalBgColorSanche">전체 배경</label>
          </li>
          <li>
            <div>
              <input
                id="metalBtnColorSanche"
                type="color"
                v-model="background_btn"
              />
            </div>
            <label for="metalBtnColorSanche">전체화면 버튼 배경</label>
          </li>
          <li>
            <div>
              <input id="metalBtnFontSanche" type="color" v-model="color_btn" />
            </div>
            <label for="metalBtnFontSanche">전체화면 버튼 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalErrFontSanche"
                type="color"
                v-model="color_current"
              />
            </div>
            <label for="metalErrFontSanche">제목/조회시간 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalPsCardSanche"
                type="color"
                v-model="background_process_card"
              />
            </div>
            <label for="metalPsCardSanche">전체 카드 배경</label>
          </li>
          <li>
            <div>
              <input
                id="metalPsFontSanche"
                type="color"
                v-model="color_process_card"
              />
            </div>
            <label for="metalPsFontSanche">진행 카드 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalSsFontSanche"
                type="color"
                v-model="color_success_card"
              />
            </div>
            <label for="metalSsFontSanche">양품 카드 글자</label>
          </li>
          <li>
            <div>
              <input
                id="metalBdFontSanche"
                type="color"
                v-model="color_bad_card"
              />
            </div>
            <label for="metalBdFontSanche">불량 카드 글자</label>
          </li>
        </ul>
        <div class="btn_wrap">
          <button
            id="metalColorSanche"
            class="btn_sub2"
            @click.prevent="showModal($event)"
          >
            초기화
          </button>
          <button class="btn_sub2" @click.prevent="submitFormMetal">
            저장
          </button>
        </div>
      </form>
    </div>
    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      @onclose="CloseModal"
      @OnYesClick="removeTH()"
    >
    </two-button-modal>
  </div>
</template>

<script>
import ModalMixin from '@/mixins/modal';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [ModalMixin],
  components: {
    TwoButtonModal,
  },
  data() {
    return {
      //실시간 온습도 현황 Color
      background_pick: '#1e2242',
      border_pick: '#1e2242',
      border_inner: '#7f84aa',
      background_temp: '#2f3664',
      color_temp: '#ffffff',
      background_division: '#252b54',
      color_division: '#81c4ff',
      background_table: '#3b427a',
      color_detail: '#ffffff',
      color_detail_temp: '#f98787',
      background_button: '#3b427a',
      color_button: '#ffffff',

      //실시간 금속검출기 현황 Color
      background_bad: '#1e2242',
      background_btn: '#3b427a',
      color_btn: '#ffffff',
      color_current: '#ffffff',
      background_process_card: '#3b427a',
      color_process_card: '#ffffff',
      color_success_card: '#7ca4d9',
      color_bad_card: '#ef6262',

      //reset modal
      my_modal_title: '',
      my_modal_content: '',
      my_modal_index: -1,
      my_isModalOpen: false,
      tempColorSanche: false,
      metalColorSanche: false,
    };
  },
  mounted() {
    this.background_pick = localStorage.getItem('articleColorSanche');
    this.border_pick = localStorage.getItem('mesborTblSanche');
    this.border_inner = localStorage.getItem('mesboriTblSanche');
    this.background_temp = localStorage.getItem('tempbgColorSanche');
    this.color_temp = localStorage.getItem('tempFontSanche');
    this.background_division = localStorage.getItem('divColorSanche');
    this.color_division = localStorage.getItem('divFontSanche');
    this.background_table = localStorage.getItem('tableColorSanche');
    this.color_detail = localStorage.getItem('detailFontSanche');
    this.color_detail_temp = localStorage.getItem('detailTempFontSanche');
    this.background_button = localStorage.getItem('btnColorSanche');
    this.color_button = localStorage.getItem('btnFontColorSanche');

    this.background_bad = localStorage.getItem('bgBadSanche');
    this.background_btn = localStorage.getItem('btnAllColorSanche');
    this.color_btn = localStorage.getItem('btnAllFontColorSanche');
    this.color_current = localStorage.getItem('errCurrentSanche');
    this.background_process_card = localStorage.getItem(
      'psBackgroundCardSanche',
    );
    this.color_process_card = localStorage.getItem('psCardFontSanche');
    this.color_success_card = localStorage.getItem('ssCardFontSanche');
    this.color_bad_card = localStorage.getItem('bdCardFontSanche');
  },

  methods: {
    showModal(e) {
      if (e.target.id == 'tempColorSanche') {
        this.tempColorSanche = true;
      } else {
        this.metalColorSanche = true;
      }

      this.my_modal_title = '알림';
      this.my_modal_content = '정말로 초기화하시겠습니까?';
      this.my_isModalOpen = true;
    },
    CloseModal() {
      this.my_modal_index = -1;
      this.my_modal_title = '';
      this.my_modal_content = '';
      this.my_isModalOpen = false;
    },
    submitFormTH() {
      localStorage.setItem('articleColorSanche', this.background_pick);
      localStorage.setItem('mesborTblSanche', this.border_pick);
      localStorage.setItem('mesboriTblSanche', this.border_inner);
      localStorage.setItem('tempbgColorSanche', this.background_temp);
      localStorage.setItem('tempFontSanche', this.color_temp);
      localStorage.setItem('divColorSanche', this.background_division);
      localStorage.setItem('divFontSanche', this.color_division);
      localStorage.setItem('tableColorSanche', this.background_table);
      localStorage.setItem('detailFontSanche', this.color_detail);
      localStorage.setItem('detailTempFontSanche', this.color_detail_temp);
      localStorage.setItem('btnColorSanche', this.background_button);
      localStorage.setItem('btnFontColorSanche', this.color_button);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeTH() {
      if (this.tempColorSanche == true) {
        localStorage.setItem('articleColorSanche', '#1e2242'); //배경
        localStorage.setItem('mesborTblSanche', '#1e2242'); //테두리외부
        localStorage.setItem('mesboriTblSanche', '#7f84aa'); //테두리내부
        localStorage.setItem('tempbgColorSanche', '#2f3664'); //온습도현황 칼라
        localStorage.setItem('tempFontSanche', '#ffffff'); //온습도현황 폰트
        localStorage.setItem('divColorSanche', '#252b54'); //구분 온습도 배경
        localStorage.setItem('divFontSanche', '#81c4ff'); //구분 온습도 폰트
        localStorage.setItem('tableColorSanche', '#3b427a'); //테이블 칼라
        localStorage.setItem('detailFontSanche', '#ffffff'); //온습도폰트
        localStorage.setItem('detailTempFontSanche', '#f98787'); //구분폰트
        localStorage.setItem('btnColorSanche', '#3b427a'); //버튼색
        localStorage.setItem('btnFontColorSanche', '#ffffff'); // 버튼폰트

        this.background_pick = '#1e2242';
        this.border_pick = '#1e2242';
        this.border_inner = '#7f84aa';
        this.background_temp = '#2f3664';
        this.color_temp = '#ffffff';
        this.background_division = '#252b54';
        this.color_division = '#81c4ff';
        this.background_table = '#3b427a';
        this.color_detail = '#ffffff';
        this.color_detail_temp = '#f98787';
        this.background_button = '#3b427a';
        this.color_button = '#ffffff';
      } else if (this.metalColorSanche == true) {
        // 금속검출기 초기화
        this.removeMetal();
      }
      this.tempColorSanche = false;
      this.metalColorSanche = false;
      this.CloseModal();
    },
    submitFormMetal() {
      localStorage.setItem('bgBadSancheSanche', this.background_bad);
      localStorage.setItem('btnAllColorSancheSanche', this.background_btn);
      localStorage.setItem('btnAllFontColorSancheSanche', this.color_btn);
      localStorage.setItem('errCurrentSancheSanche', this.color_current);
      localStorage.setItem(
        'psBackgroundCardSancheSanche',
        this.background_process_card,
      );
      localStorage.setItem('psCardFontSancheSanche', this.color_process_card);
      localStorage.setItem('ssCardFontSancheSanche', this.color_success_card);
      localStorage.setItem('bdCardFontSancheSanche', this.color_bad_card);

      this.openOneButtonModal('저장 성공', '성공적으로 저장하였습니다.');
    },
    removeMetal() {
      localStorage.setItem('bgBadSancheSanche', '#1e2242'); //금속검출기 배경
      localStorage.setItem('btnAllColorSancheSanche', '#3b427a'); //버튼색상
      localStorage.setItem('btnAllFontColorSancheSanche', '#ffffff'); //버튼폰트
      localStorage.setItem('errCurrentSancheSanche', '#ffffff'); //불량검출현황 폰트
      localStorage.setItem('psBackgroundCardSancheSanche', '#3b427a'); //카드 배경색상
      localStorage.setItem('psCardFontSancheSanche', '#ffffff'); //진행 폰트
      localStorage.setItem('ssCardFontSancheSanche', '#7ca4d9'); //양품 폰트
      localStorage.setItem('bdCardFontSancheSanche', '#ef6262'); //불량 폰트

      this.background_bad = '#1e2242';
      this.background_btn = '#3b427a';
      this.color_btn = '#ffffff';
      this.color_current = '#ffffff';
      this.background_process_card = '#3b427a';
      this.color_process_card = '#ffffff';
      this.color_success_card = '#7ca4d9';
      this.color_bad_card = '#ef6262';
    },
  },
  async created() {
    if (localStorage.getItem('articleColorSanche') == undefined) {
      localStorage.setItem('articleColorSanche', this.background_pick);
    }
    if (localStorage.getItem('mesborTblSanche') == undefined) {
      localStorage.setItem('mesborTblSanche', this.border_pick);
    }
    if (localStorage.getItem('mesboriTblSanche') == undefined) {
      localStorage.setItem('mesboriTblSanche', this.border_inner);
    }
    if (localStorage.getItem('tempbgColorSanche') == undefined) {
      localStorage.setItem('tempbgColorSanche', this.background_temp);
    }
    if (localStorage.getItem('tempFontSanche') == undefined) {
      localStorage.setItem('tempFontSanche', this.color_temp);
    }
    if (localStorage.getItem('divColorSanche') == undefined) {
      localStorage.setItem('divColorSanche', this.background_division);
    }
    if (localStorage.getItem('divFontSanche') == undefined) {
      localStorage.setItem('divFontSanche', this.color_division);
    }
    if (localStorage.getItem('tableColorSanche') == undefined) {
      localStorage.setItem('tableColorSanche', this.background_table);
    }
    if (localStorage.getItem('detailFontSanche') == undefined) {
      localStorage.setItem('detailFontSanche', this.color_detail);
    }
    if (localStorage.getItem('detailTempFontSanche') == undefined) {
      localStorage.setItem('detailTempFontSanche', this.color_detail_temp);
    }
    if (localStorage.getItem('btnColorSanche') == undefined) {
      localStorage.setItem('btnColorSanche', this.background_button);
    }
    if (localStorage.getItem('btnFontColorSanche') == undefined) {
      localStorage.setItem('btnFontColorSanche', this.color_button);
    }

    if (localStorage.getItem('bgBadSanche') == undefined) {
      localStorage.setItem('bgBadSanche', this.background_bad);
    }
    if (localStorage.getItem('btnAllColorSanche') == undefined) {
      localStorage.setItem('btnAllColorSanche', this.background_btn);
    }
    if (localStorage.getItem('btnAllFontColorSanche') == undefined) {
      localStorage.setItem('btnAllFontColorSanche', this.color_btn);
    }
    if (localStorage.getItem('errCurrentSanche') == undefined) {
      localStorage.setItem('errCurrentSanche', this.color_current);
    }
    if (localStorage.getItem('psBackgroundCardSanche') == undefined) {
      localStorage.setItem(
        'psBackgroundCardSanche',
        this.background_process_card,
      );
    }
    if (localStorage.getItem('psCardFontSanche') == undefined) {
      localStorage.setItem('psCardFontSanche', this.color_process_card);
    }
    if (localStorage.getItem('ssCardFontSanche') == undefined) {
      localStorage.setItem('ssCardFontSanche', this.color_success_card);
    }
    if (localStorage.getItem('bdCardFontSanche') == undefined) {
      localStorage.setItem('bdCardFontSanche', this.color_bad_card);
    }
  },
};
</script>

<style lang="scss" scoped></style>
