<template>
  <div
    id="contents"
    :class="{
      status_board_blue: tabIndex == 0 || tabIndex == 1,
      plc_monitor: tabIndex == 0 || tabIndex == 1,
      plc_th_status: tabIndex == 0,
      plc_metal_status: tabIndex == 1,
      plc_th_log: tabIndex == 2,
      plc_metal_log: tabIndex == 3,
      color_setting: tabIndex == 4,
      default_setting: tabIndex == 5,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToPlcMonitorPage'"
          :options="[
            { title: '실시간 온습도 현황', name: 'realtime_th_status' },
            { title: '실시간 금속검출기 현황', name: 'realtime_metal_status' },
            { title: '온습도 현황', name: 'plc_th_log' },
            { title: '금속검출기 현황', name: 'plc_metal_log' },
            { title: '현황판 색 설정', name: 'status_board_color_picker' },
            { title: '기준값 설정', name: 'default_plc_setting' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <!-- <button class="btn_admin">저장</button> -->
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">실시간 온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">실시간 금속검출기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 2 }">
            <a @click="SetOpenTabIndex(2)">온습도 현황</a>
          </li>
          <li :class="{ active: tabIndex == 3 }">
            <a @click="SetOpenTabIndex(3)">금속검출기 현황</a>
          </li>
          <li :class="{ active: tabIndex == 4 }">
            <a @click="SetOpenTabIndex(4)">현황판 색 설정</a>
          </li>
          <li :class="{ active: tabIndex == 5 }">
            <a @click="SetOpenTabIndex(5)">기준값 설정</a>
          </li>
        </ul>
      </div>
      <temp-realtime-form v-if="tabIndex == 0"></temp-realtime-form>
      <counter-realtime-form v-if="tabIndex == 1"></counter-realtime-form>
      <temp-log-form v-if="tabIndex == 2"></temp-log-form>
      <counter-log-form v-if="tabIndex == 3"></counter-log-form>
      <color-picker-form v-if="tabIndex == 4"></color-picker-form>
      <default-plc-form v-if="tabIndex == 5"></default-plc-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import TempRealtimeForm from '@/views/forms/Custom/Monitor/5/TempRealtimeForm';
import CounterRealtimeForm from '@/views/forms/Custom/Monitor/5/CounterRealtimeForm';
import TempLogForm from '@/views/forms/Custom/Monitor/3/TempLogForm';
import CounterLogForm from '@/views/forms/Custom/Monitor/3/CounterLogForm';
import ColorPickerForm from '@/views/forms/Custom/Monitor/5/ColorPickerForm';
import DefaultPlcForm from '@/views/forms/Custom/Monitor/3/DefaultPlcForm';
import AsideSelectric from '@/layouts/components/AsideSelectric';

import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    TempRealtimeForm,
    CounterRealtimeForm,
    ColorPickerForm,
    TempLogForm,
    CounterLogForm,
    DefaultPlcForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPlcMonitorPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPlcMonitorPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import 'sanche_monitoring';
</style>
