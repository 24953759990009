var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"article",class:{ active: _vm.fullScreen == true },style:(("background-color: " + _vm.articleColorSanche + ";"))},[_c('div',{staticClass:"tbl_wrap"},[_c('div',{staticClass:"tbl_option"},[_c('button',{staticClass:"btn_sub1",style:(("background-color: " + _vm.btnColorSanche + "; color: " + _vm.btnFontColorSanche + ";")),on:{"click":function($event){_vm.fullScreen = !_vm.fullScreen}}},[_vm._v(" "+_vm._s(_vm.fullScreen ? '닫기' : '전체화면')+" ")])]),_c('div',{staticClass:"table_wrap"},[_c('div',{staticClass:"mes_tbl_wrap",style:(("border: 2px solid " + _vm.mesborTblSanche))},[_c('table',{staticClass:"mes_tbl"},[_c('thead',[_c('tr',[_c('th',{style:(("border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.tempbgColorSanche + "; color: " + _vm.tempFontSanche + ";")),attrs:{"colspan":"3"}},[_vm._v(" 온습도 현황 "),_c('span',{staticClass:"date"},[_vm._v(" "+_vm._s(_vm.grouped_temphumi_logs.length == 0 ? '' : _vm.yyyy년MM월dd일HH시mm분ss초2( _vm.grouped_temphumi_logs[0].create_time )))])])]),_c('tr',[_c('th',{style:(("border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.divColorSanche + "; color: " + _vm.divFontSanche + ";"))},[_vm._v(" 구분 ")]),_c('th',{style:(("border-left: 2px solid " + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.divColorSanche + "; color: " + _vm.divFontSanche + ";"))},[_vm._v(" 온도 ")]),_c('th',{style:(("border-left: 2px solid " + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.divColorSanche + "; color: " + _vm.divFontSanche + ";"))},[_vm._v(" 습도 ")])])]),_c('tbody',_vm._l((_vm.grouped_temphumi_logs),function(item,index){return _c('tr',{key:item.id},[_c('td',{style:(_vm.grouped_temphumi_logs.length - 1 == index
                    ? ("border-left: 0; border-bottom: 0; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailFontSanche + ";")
                    : ("border-left: 0; border-bottom: 2px solid " + _vm.mesboriTblSanche + ";\n                  background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailFontSanche + ";"))},[_vm._v(" "+_vm._s(((item.plc_location_name) + ": " + (item.plc_sensor_type_name)))+" ")]),_c('td',{style:(_vm.grouped_temphumi_logs.length - 1 == index
                    ? ("border-left: 2px solid" + _vm.mesboriTblSanche + "; border-bottom: 0; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";")
                    : ("border-left: 2px solid" + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";"))},[_vm._v(" "+_vm._s(item.value)+"℃ ")]),_c('td',{style:(_vm.grouped_temphumi_logs.length - 1 == index
                    ? ("border-left: 2px solid" + _vm.mesboriTblSanche + "; border-bottom: 0; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";")
                    : ("border-left: 2px solid" + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";"))},[_vm._v(" "+_vm._s(item.humi_value)+"% ")])])}),0)])]),_c('div',{staticClass:"mes_tbl_wrap",style:(("border: 2px solid " + _vm.mesborTblSanche + ";"))},[_c('table',{staticClass:"mes_tbl"},[_c('thead',[_c('tr',[_c('th',{style:(("border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.tempbgColorSanche + "; color: " + _vm.tempFontSanche + ";")),attrs:{"colspan":"3"}},[_vm._v(" 온도 현황 "),_c('span',{staticClass:"date"})])]),_c('tr',[_c('th',{style:(("border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.divColorSanche + "; color: " + _vm.divFontSanche + ";"))},[_vm._v(" 구분 ")]),_c('th',{style:(("border-left: 2px solid " + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.divColorSanche + "; color: " + _vm.divFontSanche + ";"))},[_vm._v(" 온도 ")])])]),_c('tbody',_vm._l((_vm.sorted_plc_temperature_logs),function(item,index){return _c('tr',{key:item.id},[_c('td',{style:(_vm.sorted_plc_temperature_logs.length - 1 == index
                    ? ("border-left: 0; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailFontSanche + ";")
                    : ("border-left: 0; border-bottom: 2px solid " + _vm.mesboriTblSanche + ";\n                  background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailFontSanche + ";"))},[_vm._v(" "+_vm._s(((item.plc_location_name) + ": " + (item.plc_sensor_type_name)))+" ")]),_c('td',{style:(_vm.sorted_plc_temperature_logs.length - 1 == index
                    ? ("border-left: 2px solid" + _vm.mesboriTblSanche + "; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";")
                    : ("border-left: 2px solid" + _vm.mesboriTblSanche + "; border-bottom: 2px solid " + _vm.mesboriTblSanche + "; background-color: " + _vm.tableColorSanche + "; color: " + _vm.detailTempFontSanche + ";"))},[_vm._v(" "+_vm._s(item.value)+"℃ ")])])}),0)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }