<template>
  <div
    class="article"
    :class="{ active: fullScreen == true }"
    :style="`background-color: ${articleColorSanche};`"
  >
    <div class="tbl_wrap">
      <div class="tbl_option">
        <button
          class="btn_sub1"
          @click="fullScreen = !fullScreen"
          :style="
            `background-color: ${btnColorSanche}; color: ${btnFontColorSanche};`
          "
        >
          {{ fullScreen ? '닫기' : '전체화면' }}
        </button>
      </div>

      <div class="table_wrap">
        <div
          class="mes_tbl_wrap"
          :style="`border: 2px solid ${mesborTblSanche}`"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="3"
                  :style="
                    `border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${tempbgColorSanche}; color: ${tempFontSanche};`
                  "
                >
                  온습도 현황
                  <span class="date">
                    {{
                      grouped_temphumi_logs.length == 0
                        ? ''
                        : yyyy년MM월dd일HH시mm분ss초2(
                            grouped_temphumi_logs[0].create_time,
                          )
                    }}</span
                  >
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${divColorSanche}; color: ${divFontSanche};`
                  "
                >
                  구분
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${divColorSanche}; color: ${divFontSanche};`
                  "
                >
                  온도
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${divColorSanche}; color: ${divFontSanche};`
                  "
                >
                  습도
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in grouped_temphumi_logs" :key="item.id">
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 0; border-bottom: 0; background-color: ${tableColorSanche}; color: ${detailFontSanche};`
                      : `border-left: 0; border-bottom: 2px solid ${mesboriTblSanche};
                    background-color: ${tableColorSanche}; color: ${detailFontSanche};`
                  "
                >
                  {{
                    `${item.plc_location_name}: ${item.plc_sensor_type_name}`
                  }}
                </td>
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTblSanche}; border-bottom: 0; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                      : `border-left: 2px solid${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                  "
                >
                  {{ item.value }}℃
                </td>
                <td
                  :style="
                    grouped_temphumi_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTblSanche}; border-bottom: 0; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                      : `border-left: 2px solid${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                  "
                >
                  {{ item.humi_value }}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          class="mes_tbl_wrap"
          :style="`border: 2px solid ${mesborTblSanche};`"
        >
          <table class="mes_tbl">
            <thead>
              <tr>
                <th
                  colspan="3"
                  :style="
                    `border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${tempbgColorSanche}; color: ${tempFontSanche};`
                  "
                >
                  온도 현황
                  <span class="date"></span>
                </th>
              </tr>
              <tr>
                <th
                  :style="
                    `border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${divColorSanche}; color: ${divFontSanche};`
                  "
                >
                  구분
                </th>
                <th
                  :style="
                    `border-left: 2px solid ${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${divColorSanche}; color: ${divFontSanche};`
                  "
                >
                  온도
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in sorted_plc_temperature_logs"
                :key="item.id"
              >
                <td
                  :style="
                    sorted_plc_temperature_logs.length - 1 == index
                      ? `border-left: 0; background-color: ${tableColorSanche}; color: ${detailFontSanche};`
                      : `border-left: 0; border-bottom: 2px solid ${mesboriTblSanche};
                    background-color: ${tableColorSanche}; color: ${detailFontSanche};`
                  "
                >
                  {{
                    `${item.plc_location_name}: ${item.plc_sensor_type_name}`
                  }}
                </td>
                <td
                  :style="
                    sorted_plc_temperature_logs.length - 1 == index
                      ? `border-left: 2px solid${mesboriTblSanche}; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                      : `border-left: 2px solid${mesboriTblSanche}; border-bottom: 2px solid ${mesboriTblSanche}; background-color: ${tableColorSanche}; color: ${detailTempFontSanche};`
                  "
                >
                  {{ item.value }}℃
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetchMixin from '@/mixins/fetch';
import { mapGetters } from 'vuex';
import { yyyy년MM월dd일HH시mm분ss초 } from '@/utils/func';

export default {
  mixins: [fetchMixin],
  data() {
    return {
      fullScreen: false,
      str_time: null,
      timerCount: 10,
      timerMax: 10,
      interval: null,
      timeInterval: null,
      currentTimeInterval: null,

      articleColorSanche: localStorage.getItem('articleColorSanche'), //배경
      mesborTblSanche: localStorage.getItem('mesborTblSanche'), //테두리
      mesboriTblSanche: localStorage.getItem('mesboriTblSanche'), //테두리 내부
      tempbgColorSanche: localStorage.getItem('tempbgColorSanche'), //온습도현황 배경
      tempFontSanche: localStorage.getItem('tempFontSanche'), //온습도현황 폰트
      divColorSanche: localStorage.getItem('divColorSanche'), // 구분 온습도 배경
      divFontSanche: localStorage.getItem('divFontSanche'), //구분 온습도 폰트
      tableColorSanche: localStorage.getItem('tableColorSanche'), //테이블 배경
      detailFontSanche: localStorage.getItem('detailFontSanche'), //데이터 구분 폰트
      detailTempFontSanche: localStorage.getItem('detailTempFontSanche'), //데이터 온습도 폰트
      btnColorSanche: localStorage.getItem('btnColorSanche'), //버튼 배경
      btnFontColorSanche: localStorage.getItem('btnFontColorSanche'), //버튼 폰트
    };
  },
  computed: {
    ...mapGetters({
      plc_sensor_logs: 'getPlcSensorLog',
      plc_temperature_logs: 'getTemperatureSensorLog',
      plc_tempHumi_logs: 'getTemperatureHumiditySensorLog',
      tabIndex: 'getOpenTabIndexFromPlcMonitorPage',
    }),

    sorted_plc_temperature_logs() {
      let clone = this.lodash.clonedeep(this.plc_temperature_logs);
      return clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);
    },
    grouped_temphumi_logs() {
      let clone = this.lodash.clonedeep(this.plc_tempHumi_logs);
      clone = clone.sort((a, b) => a.plc_sensor_id - b.plc_sensor_id);

      let lists = [];
      let location_id_temp = null;
      let duplicate_count = 2;
      clone.forEach((element, index) => {
        if (index % 2 == 0) {
          if (element.plc_location_id == location_id_temp) {
            element.plc_sensor_type_name =
              element.plc_sensor_type_name + duplicate_count;
            duplicate_count++;
          } else {
            duplicate_count = 2;
          }
          location_id_temp = element.plc_location_id;
          element.humi_value = clone[index + 1].value;
          lists.push(element);
        }
      });
      return lists;
    },
  },
  destroyed() {
    console.log('destroyed');
    clearInterval(this.interval);
    clearInterval(this.timeInterval);
    clearInterval(this.currentTimeInterval);
  },
  methods: {
    yyyy년MM월dd일HH시mm분ss초2(date2) {
      const date = new Date(date2);
      var MM = date.getMonth() + 1; // getMonth() is zero-based
      var dd = date.getDate();
      var hh = date.getHours();
      var mm = date.getMinutes();
      var ss = date.getSeconds();

      return [
        date.getFullYear(),
        '년 ' + (MM > 9 ? MM : '0' + MM),
        '월 ' + (dd > 9 ? dd : '0' + dd),
        '일 (' + (hh > 9 ? hh : '0' + hh),
        ':' + (mm > 9 ? mm : '0' + mm),
        ':' + (ss > 9 ? ss : '0' + ss),
        ')',
      ].join('');
    },
  },
  async created() {
    this.timerCount = this.timerMax;
    await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');

    this.interval = setInterval(async () => {
      this.timerCount = this.timerMax;

      if (this.tabIndex == 0) {
        await this.FETCH_NO_SPINNER('FETCH_PLC_SENSOR_LOG_NOW', '온습도현황');
      }
      clearInterval(this.timeInterval);
      this.timeInterval = setInterval(() => {
        this.timerCount -= 1;
      }, 1000);
    }, this.timerMax * 1000);
    this.timeInterval = setInterval(() => {
      this.timerCount -= 1;
    }, 1000);
    this.currentTimeInterval = setInterval(() => {
      this.str_time = yyyy년MM월dd일HH시mm분ss초(new Date());
    }, 1000);
  },
};
</script>

<style lang="scss" scoped></style>
